import React from "react"
import HamburgerMenu from "react-hamburger-menu"
import Headroom from "react-headroom"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../Link"
import { StaticQuery, graphql } from "gatsby"

import MainNav from "../MainNav"
import MobileNav from "../MobileNav"

const ScrollableWrapper = styled(Headroom)`
  max-height: 130px;
  min-height: 110px;
  ${tw`bg-grey xl:bg-white`}

  .headroom {
    ${tw`w-full bg-grey xl:bg-white`}
    z-index: 999 !important;

    @media not all and (min-resolution: 0.001dpcm) {
      @supports (-webkit-appearance: none) {
        position: fixed !important;
      }
    }

    .home & {
      ${tw`xl:bg-grey`}
    }

    .not-found-page & {
      ${tw`bg-white`}
    }

    .show-menu & {
      .mobile-menu-icon {
        span {
          height: 3px !important;
        }
      }
    }
  }

  .home & {
    ${tw`md:absolute md:top-0 md:w-full`}
  }

  .not-found-page & {
    ${tw`bg-white`}
  }
`

const MainHeader = styled.header`
  max-height: 130px;

  &.mobile-menu-active {
    .mobile-menu-icon-wrap span {
      background-color: #fff !important;
    }
  }

  .logo-wrap,
  .mobile-menu-icon-wrap {
    z-index: 99;
  }

  .logo {
    transition: all 200ms ease-in;

    .headroom--unfixed & {
      max-width: 64px;
    }

    .headroom--scrolled & {
      max-width: 50px;

      @media (min-width: 1024px) {
        max-width: 64px;
      }
    }

    .home .headroom--unfixed & {
      @media (min-width: 1280px) {
        max-width: 125px;
        height: 160px;
      }
    }
  }

  .header-inner {
    .home .headroom--unfixed & {
      ${tw`pb-0`}
    }
  }
`

class Header extends React.Component {
  state = {
    open: false,
  }

  onWindowResize = () => {
    if (window.matchMedia(`(min-width: 1024px)`).matches) {
      document.getElementsByTagName("html")[0].classList.remove("show-menu")
    }
  }

  componentDidMount = () => {
    if (window) {
      window.addEventListener("resize", this.onWindowResize)
    }
  }

  componentWillUnmount = () => {
    if (window) {
      window.removeEventListener("resize", this.onWindowResize)
    }
  }

  handleClick() {
    this.setState({
      open: !this.state.open,
    })
    document.getElementsByTagName("html")[0].classList.toggle("show-menu")
  }

  render() {
    const { menuItems } = this.props.data.edges[0].node

    return (
      <ScrollableWrapper>
        <MainHeader
          id="main-header"
          className={this.state.open ? "mobile-menu-active" : ""}
        >
          <div className="max-w-xl mx-auto flex flex-wrap items-center justify-between py-9 lg:py-12 px-18 md:px-40 lg:px-60 xxl:px-0 header-inner">
            <div className="w-2/4 lg:w-auto logo-wrap">
              <Link to="/">
                <img
                  src={
                    this.props.siteSettings.siteGeneralSettings.acfSiteSettings
                      .headerLogo.localFile.publicURL
                  }
                  alt="Header Logo"
                  className="logo"
                />
              </Link>
            </div>

            <div className="lg:hidden w-2/4 mobile-menu-icon-wrap">
              <HamburgerMenu
                isOpen={this.state.open}
                menuClicked={this.handleClick.bind(this)}
                width={23}
                height={20}
                strokeWidth={2}
                rotate={0}
                color="#23225A"
                borderRadius={0}
                animationDuration={0.5}
                className="mobile-menu-icon float-right"
              />
            </div>

            <MainNav
              data={menuItems}
              button={
                this.props.siteSettings.siteGeneralSettings.acfSiteSettings
                  .headerMenuButton
              }
            />
            <MobileNav
              data={menuItems}
              button={
                this.props.siteSettings.siteGeneralSettings.acfSiteSettings
                  .headerMenuButton
              }
            />
          </div>
        </MainHeader>
      </ScrollableWrapper>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query Menu {
        mainMenu: allWpMenu(filter: { locations: { eq: PRIMARY_NAVIGATION } }) {
          edges {
            node {
              menuItems {
                nodes {
                  id
                  url
                  label
                  parentId
                  childItems {
                    nodes {
                      id
                      url
                      label
                    }
                  }
                }
              }
            }
          }
        }
        siteSettings: wp {
          siteGeneralSettings {
            acfSiteSettings {
              headerLogo {
                localFile {
                  publicURL
                }
              }
              headerMenuButton {
                target
                title
                url
              }
            }
          }
        }
      }
    `}
    render={({ mainMenu, siteSettings }) => (
      <Header data={mainMenu} siteSettings={siteSettings} />
    )}
  />
)
