/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext, useRef, useEffect, useState } from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { LayoutContext } from "../context/LayoutContext"
import { TransitionPortal } from "gatsby-plugin-transition-link"

import Header from "./Header/"
import Footer from "./Footer/"

const LinkAnimationPortal = () => {
	const transitionCover = useRef(null)
	//eslint-disable-next-line no-unused-vars
	const [ coverRef, layoutRef, setCoverRef ] = useContext(LayoutContext);
	useEffect(() => {
		setCoverRef(transitionCover)
	}, [setCoverRef, transitionCover])

  return (
    <TransitionPortal>
      <div
				className='transition-cover fixed top-0 left-0 w-screen h-screen'
        ref={transitionCover}
        style={{
          background: "#23225a",
          transform: "translateY(100%)",
        }}
      />
    </TransitionPortal>
  )
}

const ContentWrapper = ({ children }) => {
	const layoutReference = useRef(null)
	//eslint-disable-next-line no-unused-vars
	const [ coverRef, layoutRef, setCoverRef, setLayoutRef ] = useContext(LayoutContext);
	useEffect(() => {
		setLayoutRef(layoutReference)
	}, [layoutReference, setLayoutRef])

	return (
		<div ref={layoutReference} className="content-wrapper">
			<Header />
			<main id="main">{children}</main>
			<Footer />
		</div>
	)
}

const Layout = ({ children, path }) => {
	const [ coverRef, setCoverRef ] = useState();
	const [ layoutRef, setLayoutRef ] = useState();

  return (
    <LayoutContext.Provider value={[coverRef, layoutRef, setCoverRef, setLayoutRef]}>
      <Helmet>
        {path && (
          <body
            className={`${path === "/" ? "home" : path.replace("/", "")}`}
          />
        )}
      </Helmet>
			<ContentWrapper children={children} />
			<LinkAnimationPortal />
    </LayoutContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
}

Layout.defaultProps = {
  path: "",
}

export default Layout
