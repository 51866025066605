import React, { useContext } from "react"
import TransitionLink from 'gatsby-plugin-transition-link'
import gsap from 'gsap'
import { LayoutContext } from "../../context/LayoutContext"
// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({
  children,
  to,
  activeClassName = "active",
  partiallyActive = true,
  ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to)
	const [ transitionCover, layoutReference ] = useContext(LayoutContext)
	// Use Gatsby Link for internal links, and <a> for others
	const verticalAnimation = ({ length }, direction) => {
    const directionTo = direction === 'up' ? '-100%' : '100%'
    const directionFrom = direction === 'up' ? '100%' : '-100%'

    // convert ms to s for gsap
    const seconds = length

    return gsap.timeline()
      .add(() => { document.getElementsByTagName('html')[0].classList.add('page-transitioning') })
      .set(transitionCover.current, { y: directionFrom })
      .to(transitionCover.current, {
        y: '0%',
        ease: "power1.easeInOut",
        duration: seconds / 3,
      })
      .set(layoutReference.current, { opacity: 0 })
      .to(transitionCover.current, {
        y: directionTo,
        ease: "power1.easeIn",
        duration: seconds / 2,
      })
      .add(() => { document.getElementsByTagName('html')[0].classList.remove('page-transitioning') })
	};

  if (internal) {
    return (
      <TransitionLink
				exit={{
					length: 1.2,
					trigger: ({ exit }) => verticalAnimation(exit, 'up'),
				}}
				entry={{ delay: 0.5, length: 1 }}
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </TransitionLink>
    )
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  )
}
export default Link
