import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Link from "../Link"
import { StaticQuery, graphql } from "gatsby"

import Facebook from "../../assets/facebook.inline.svg"
import Linkedin from "../../assets/linkedin.inline.svg"

const Branches = styled.div`
  ${tw`max-w-xl mx-auto pt-50 xl:pt-100 pb-32 flex flex-wrap px-18 md:px-40 xxl:px-0`}

  svg {
    g > circle {
      transition: all 300ms ease-in;
    }

    :hover {
      g > circle {
        fill: #111948;
      }
    }
  }
`

const BranchBox = styled.div`
  ${tw`font-normal text-center sm:text-left`}

  .footer-logo {
    img {
      max-width: 79px;
      ${tw`w-full mx-auto`}

      @media (min-width: 640px) {
        margin: 0;
      }
    }
  }
`

const FooterBottom = styled.div`
  ${tw`max-w-xl mx-auto w-full pb-30 px-18 md:px-40 xxl:px-0 text-center font-normal`}
  color: #acacac;
  font-size: 12px;

  @media (min-width: 640px) {
    ${tw`w-full pb-32 flex mx-auto xl:pb-34`}
  }

  .footer-inner-wrp {
    border-top: 1px solid #e0e0e0;

    .copyright-links-wrp {
      .copyright-links {
        a {
          display: inline-block;
          position: relative;
          z-index: 1;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            z-index: 1;
            width: 2px;
            height: 55%;
            background-color: #bcbcbc;
          }

          &:last-child {
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }
`

const Footer = props => {
  return (
    <StaticQuery
      query={graphql`
        query Footer {
          wp {
            siteGeneralSettings {
              acfSiteSettings {
                blockTitle1
                blockTitle2
                blockTitle3
                fieldGroupName
                foooterFacebookLink
                footerAddress
                footerFax
                footerLinkedinLink
                footerTelephone
                footerBottomPageOne {
                  target
                  title
                  url
                }
                footerBottomPageTwo {
                  target
                  title
                  url
                }
                footerLogo {
                  localFile {
                    publicURL
                  }
                }
                footerLinks1 {
                  footerLink1 {
                    target
                    title
                    url
                  }
                }
                footerLinks2 {
                  footerLink2 {
                    target
                    title
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const {
          wp: {
            siteGeneralSettings: { acfSiteSettings },
          },
        } = data

        return (
          <footer>
            <Branches>
              <BranchBox className="w-full lg:w-2/12 mb-32 lg:mb-0">
                <div className="flex flex-col footer-logo">
                  <img
                    src={acfSiteSettings.footerLogo.localFile.publicURL}
                    alt="Footer Logo"
                  />
                </div>
              </BranchBox>

              <BranchBox className="w-full sm:w-6/12 lg:w-4/12 mb-40 lg:mb-0">
                <div className="flex flex-col">
                  <h6 className="mb-4 text-base md:text-xl font-fb uppercase mb-5 md:mb-13 text-primary">
                    {acfSiteSettings.blockTitle1}
                  </h6>
                  <ul className="leading-7 md:leading-9 font-fm font-medium">
                    {acfSiteSettings.footerLinks1.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.footerLink1.url}
                          target={item.footerLink1.target}
                        >
                          {item.footerLink1.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </BranchBox>

              <BranchBox className="w-full sm:w-6/12 lg:w-1/4 mb-40 lg:mb-0 sm:pl-21 lg:pl-0">
                <div className="flex flex-col">
                  <h6 className="mb-4 text-base md:text-xl font-fb uppercase mb-5 md:mb-13 text-primary">
                    {acfSiteSettings.blockTitle2}
                  </h6>
                  <ul className="leading-7 md:leading-9 font-fm font-medium">
                    {acfSiteSettings.footerLinks2.map((item, index) => (
                      <li key={index}>
                        <Link
                          to={item.footerLink2.url}
                          target={item.footerLink2.target}
                        >
                          {item.footerLink2.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </BranchBox>

              <BranchBox className="w-full sm:w-6/12 lg:w-1/4">
                <div className="flex flex-col">
                  <h6 className="mb-4 text-base md:text-xl font-fb uppercase mb-5 md:mb-13 text-primary">
                    {acfSiteSettings.blockTitle3}
                  </h6>
                  <div className="leading-7 md:leading-9 font-fm font-medium">
                    <address
                      className="mb-3 not-italic mb-25 md:mb-17 xl:mb-24"
                      dangerouslySetInnerHTML={{
                        __html: acfSiteSettings.footerAddress,
                      }}
                    />
                    {acfSiteSettings.footerTelephone && (
                      <div className="tele">
                        Tel:{" "}
                        <a
                          className="cursor-pointer"
                          href={"tel:" + acfSiteSettings.footerTelephone}
                        >
                          {acfSiteSettings.footerTelephone}
                        </a>
                      </div>
                    )}
                    {acfSiteSettings.footerFax && (
                      <div className="tele">
                        Fax: <span>{acfSiteSettings.footerFax}</span>
                      </div>
                    )}
                  </div>
                  <div className="socail-media mt-26 xl:mt-28">
                    <ul className="flex justify-center sm:justify-start">
                      {acfSiteSettings.foooterFacebookLink && (
                        <li className="mr-11">
                          <Link
                            href={acfSiteSettings.foooterFacebookLink}
                            target="_blank"
                          >
                            <Facebook />
                          </Link>
                        </li>
                      )}
                      {acfSiteSettings.footerLinkedinLink && (
                        <li>
                          <Link
                            href={acfSiteSettings.footerLinkedinLink}
                            target="_blank"
                          >
                            <Linkedin />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </BranchBox>
            </Branches>

            <FooterBottom>
              <div className="footer-inner-wrp pt-17 w-full justify-between sm:flex">
                <div className="copyright-links-wrp sm:flex pb-15 sm:pb-0">
                  <div className="width-full text-grey-light">
                    © {new Date().getFullYear()} – Paramaom. All Rights
                    Reserved.
                  </div>
                  <div className="copyright-links text-grey-light">
                    {acfSiteSettings.footerBottomPageOne && (
                      <Link
                        className="pl-2 pr-4"
                        to={acfSiteSettings.footerBottomPageOne.url}
                        target={acfSiteSettings.footerBottomPageOne.target}
                      >
                        {acfSiteSettings.footerBottomPageOne.title}
                      </Link>
                    )}
                    {acfSiteSettings.footerBottomPageTwo && (
                      <Link
                        className="pl-4"
                        to={acfSiteSettings.footerBottomPageTwo.url}
                        target={acfSiteSettings.footerBottomPageTwo.target}
                      >
                        {acfSiteSettings.footerBottomPageTwo.title}
                      </Link>
                    )}
                  </div>
                </div>

                <div className="text-grey-light">
                  Website by{" "}
                  <Link href="https://smashy.design" target="_blank">
                    Smashy Design
                  </Link>
                </div>
              </div>
            </FooterBottom>
          </footer>
        )
      }}
    ></StaticQuery>
  )
}

export default Footer
