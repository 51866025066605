/* eslint jsx-a11y/click-events-have-key-events: 0 jsx-a11y/no-noninteractive-element-interactions: 0 */
import React from "react"
import Link from "../Link"
import styled from "styled-components"
import tw from "tailwind.macro"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"

const MobileNavWrap = styled.div`
  ${tw`lg:hidden overflow-hidden absolute top-0 left-0 w-screen h-screen`}
  transition: all 0.5s ease-in-out;
  background: transparent linear-gradient(180deg, #fab317 0%, #f26423 100%) 0%
    0% no-repeat padding-box;
  transform: translateY(-100%);

  .mobile-nav-inner {
    ${tw`overflow-auto`}
    margin-top: 135px;
    height: calc(100% - 135px);
    transition: all 0.2s ease-in-out;

    a {
      &.active {
        ${tw`text-primary pointer-events-none`}
      }
      :hover {
        ${tw`text-primary`}
      }
    }

    ul {
      ${tw`px-18 md:px-40 overflow-auto`}
    }
  }

  .has-sub-menu {
    ${tw`relative`}
    transition: all 0.2s ease-in-out;

    ul {
      ${tw`absolute top-0 opacity-0 invisible pl-8`}
      transition: visibility 0s, opacity 0.2s linear;
    }

    &.sub-menu-expand {
      background: rgb(255 255 255 / 0.2) 0% 0%;
      ${tw`-mx-18 md:-mx-40 px-18 md:px-40`}

      ul {
        ${tw`relative opacity-100 visible`}
      }
    }
  }

  .mobile-menu-active & {
    transform: none;
  }
`

class MobileNav extends React.Component {
  state = {
    expanded: null,
  }

  toggleExpand = id => {
    this.setState(prevState => ({
      expanded: prevState.expanded === id ? null : id,
    }))
  }

  MainMenuGenerator = menuData => {
    let menu = menuData.map(item => {
      const subMenu = this.SubMenuGenerator(item.id, menuData)
      return item.parentId === null ? (
        <li
          key={item.id}
          className={classNames("py-8", {
            "has-sub-menu": subMenu !== null,
            "sub-menu-expand": this.state.expanded === item.id && subMenu,
            "sub-menu-collapse": this.state.expanded !== item.id && subMenu,
          })}
        >
          <Link
            className="uppercase font-bold text-2xl text-white"
            to={item.url}
            onClick={() => {
              this.toggleExpand(item.id)
              if (subMenu) {
              } else {
                document
                  .getElementsByTagName("html")[0]
                  .classList.remove("show-menu")
              }
            }}
          >
            {item.label}
            {subMenu && <FontAwesomeIcon icon={faAngleDown} className="ml-8" />}
          </Link>
          {subMenu}
        </li>
      ) : null
    })
    return menu
  }

  SubMenuGenerator = (parentId, menuData) => {
    const subMenuData = menuData.filter(menu => menu.parentId === parentId)
    let menu = null
    if (subMenuData.length > 0) {
      menu = (
        <ul className="sub-menu">
          {subMenuData.map(item => (
            <li key={item.id} className="pt-8">
              <Link
                className="uppercase font-bold text-xl text-white"
                to={item.url}
                onClick={() => {
                  document
                    .getElementsByTagName("html")[0]
                    .classList.remove("show-menu")
                }}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      )
    }
    return menu
  }

  render() {
    return (
      <MobileNavWrap>
        <div className="mobile-nav-inner">
          <ul className="relative">
            {this.MainMenuGenerator(this.props.data.nodes)}
            {this.props.button && (
              <li className="py-8">
                <Link
                  className="uppercase font-bold text-2xl text-white"
                  to={this.props.button.url}
                  target={this.props.button.target}
                  onClick={() => {
                    document
                      .getElementsByTagName("html")[0]
                      .classList.remove("show-menu")
                  }}
                >
                  {this.props.button.title}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </MobileNavWrap>
    )
  }
}

export default MobileNav
