import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import tw from "tailwind.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import Link from "../Link"

const MainNavWrap = styled.div`
  ${tw`hidden lg:flex items-center`}

  ul {
    a {
      letter-spacing: 0.6px;

      @media (min-width: 1280px) {
        letter-spacing: 0.8px;
      }

      &.active {
        ${tw`text-secondary`}
      }
    }
  }

  li.has-sub-menu {
    ${tw`relative`}

    &:hover {
      .sub-menu {
        ${tw`block opacity-100 pointer-events-auto`}
      }
    }

    > a {
      ${tw`pb-8`}
    }

    svg {
      max-width: 10px;
      max-height: 15px;
    }
  }

  ul.sub-menu {
    ${tw`absolute hidden opacity-0 pointer-events-none`}
    padding: 10px 18px 12px;
    top: 140%;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 20px 5px;
    min-width: 160px;

    @media (min-width: 1280px) {
      min-width: 180px;
    }

    :before {
      content: "";
      border-top: 6px solid rgba(255, 255, 255, 0.5);
      border-left: 6px solid rgba(255, 255, 255, 0.5);
      transform-origin: 0 100%;
      transform: rotate(45deg);
      z-index: -1;
      ${tw`absolute w-6 h-6 bg-white`}
      top: -12px;
      left: 10px;
    }

    li {
      border-bottom: 1px solid #ededed;
      ${tw`py-3 text-xs`}

      @media (min-width: 1280px) {
        font-size: 15px;
      }

      &:last-child {
        border-bottom: none;
        ${tw`pb-0`}
      }

      a {
        letter-spacing: 1.5px;
      }
    }
  }
`

const NavbarButtonWrap = styled.div``

const MainMenuGenerator = menuData => {
  let menu = menuData.map(item => {
    const subMenu = SubMenuGenerator(item.id, menuData)
    return item.parentId === null ? (
      <li
        key={item.id}
        className={classNames("mr-18 xl:mr-24 xxl:mr-30", {
          "has-sub-menu": subMenu !== null,
        })}
      >
        <Link
          className="text-black-dark"
          to={item.url}
          onClick={e => {
            if (e.target.href.split("").pop() === "#") {
              e.preventDefault()
            }
          }}
        >
          {item.label}
          {subMenu && (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ml-2 xl:ml-8 dropdown-icon"
            />
          )}
        </Link>
        {subMenu}
      </li>
    ) : null
  })
  return menu
}

const SubMenuGenerator = (parentId, menuData) => {
  const subMenuData = menuData.filter(menu => menu.parentId === parentId)
  let menu = null
  if (subMenuData.length > 0) {
    menu = (
      <ul className="bg-white rounded-lg text-black hover:block sub-menu">
        {subMenuData.map(item => (
          <li key={item.id}>
            <Link className="text-black-dark" to={item.url}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    )
  }
  return menu
}

const MainNav = ({ data, button }) => {
  return (
    <MainNavWrap>
      <ul className="flex uppercase font-medium text-black text-xs xl:text-base">
        {MainMenuGenerator(data.nodes)}
      </ul>
      <NavbarButtonWrap>
        <Link
          className="btn-secondary btn-secondary-rounded-sm"
          to={button.url}
          target={button.target}
        >
          {button.title}
        </Link>
      </NavbarButtonWrap>
    </MainNavWrap>
  )
}

export default MainNav
